/**
 * 导出通用配置
 */
module.exports = {
  // 标题，此项修改后需要重启项目
  title: '归零码',
  // 网站标题
  siteTitle: '归零码',
  // 网站加载时显示的内容
  siteLoading: '正在为您加载系统资源，请耐心等待',
  // 网站描述
  siteDescription:
    '归零码AI原生模板SASS应用',
  // 网站关键字
  siteKeywords: '归零码,AI原生应用,模板,RZCODE',
  // 白名单配置
  allowlist: ['/', '/blog', '/member', '/article/:id'],
  // 超级管理员
  superAdmin: 'superAdmin',
  // 所有权限
  allPermission: '*:*:*',
  // 备案号
  recordTitle: '鄂ICP备2021012672号-2'
}
