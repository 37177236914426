import request from '@/utils/request'

/**
 * 分页获取系统酒店
 */
export const reqPageListHotels = (data) => {
  return request({
    url: '/hotel/pageListHotels',
    method: 'post',
    data
  })
}
/**
 * 新增店长酒店
 */
export const reqAddBossHotel = (hotelId) => {
  return request({
    url: `/hotel/addBossHotel/${hotelId}`,
    method: 'get'
  })
}

/**
 * 获取店长酒店
 * @param data
 */
export const reqListBossHotels = () => {
  return request({
    url: '/hotel/listBossHotels',
    method: 'get'
  })
}

/**
 * 解绑酒店
 */
export const requestUnbindHotel = (id) => {
  return request({
    url: `/hotel/unbindHotel/${id}`,
    method: 'delete'
  })
}

/**
 * 获取酒店
 */
export const requestListMyHotels = () => {
  return request({
    url: '/hotel/listSelfHotels',
    method: 'get'
  })
}

/**
 * 根据省份ID获取城市列表
 */
export const requestListCities = (id) => {
  return request({
    url: `/hotel/listCities/${id}`,
    method: 'get'
  })
}

/**
 *获取省份列表
 */
export const requestListProvinces = () => {
  return request({
    url: '/hotel/listProvinces',
    method: 'get'
  })
}

/**
 * 获取竞争酒店
 */
export const reqListRivalHotels = (id) => {
  return request({
    url: `/hotel/listRivalHotels/${id}`,
    method: 'get'
  })
}

/**
 * 删除竞争酒店
 * @param hotelId
 */
export const reqDelRivalHotel = (data) => {
  return request({
    url: '/hotel/delRivalHotel',
    method: 'post',
    data
  })
}

/**
 * 新增竞品酒店
 * @param data
 */
export const reqAddRivalHotel = (data) => {
  return request({
    url: '/hotel/addRivalHotel',
    method: 'post',
    data
  })
}

/**
 * 酒店交接
 * @param data
 */
export const reqTxHotel = (data) => {
  return request({
    url: '/hotel/txHotel',
    method: 'post',
    data
  })
}
