<template>
  <section class="account-container">
    <!-- 头部 -->
    <nav-bar/>
    <!-- 中间 -->
    <div class="account-content">
      <div class="account-main">
        <!--背景图-->
        <bg-img class="bg-img"/>
        <side-bar/>
        <!-- 正文内容 -->
        <div class="right-content-box">
          <Title :title="(route.meta.title)"/>
          <div class="router-box">
            <router-view/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import BgImg from './components/BgImg'
import NavBar from './components/NavBar'
import SideBar from './components/SideBar'
import Title from './components/Title'
import { useRoute } from 'vue-router'
const route = useRoute()

</script>
<style lang="scss">
.account-container{
  background:url(../layout/components/assets/imgs/user-bg.jpg);
  background-size: cover;
  background-attachment: fixed;
  min-height: calc(100vh);
  position: relative;
  overflow: hidden;
  padding-bottom: 60px;
}
.account-content{

  .account-main {
    min-height: calc(100vh - 180px);
    box-shadow: 0 5px 21px 0 rgb(78 78 78 / 5%);
    background-color: #ffffff;
    border-radius: 0 0 5px 5px;
    position: relative;
    z-index: 0;
    box-sizing: border-box;
    display: flex;
    border-radius: 4px;
    overflow: hidden;

    .bg-img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;

      img {
        width: 100%;
        height: 280px;
      }
    }
    .right-content-box {
      flex: 1;
      min-height: 300px;
      margin: 270px 0 0px 30px;
      box-sizing: border-box;

      .router-box {
        padding: 10px 20px;
      }
    }
  }
}

</style>
