<template>
  <component :is="type" v-bind="linkProps()">
    <slot/>
  </component>
</template>

<script setup>
import { isExternal } from '@/utils/validate'
import { computed } from '@vue/runtime-core'

const props = defineProps({
  to: {
    type: [String, Object],
    required: true
  }
})

const isExt = computed(() => isExternal(props.to))

const type = computed(() => (isExt.value) ? 'a' : 'router-link')

const linkProps = () => (isExt.value)
  ? {
      href: props.to,
      target: '_blank',
      rel: 'noopener'
    }
  : {
      to: props.to
    }
</script>
