import request from '@/utils/request'

/**
 * 获取登录地址
 */
export const getLoginUrl = (source) => {
  return request({
    url: `/auth/third/login/getLoginUrl/${source}`,
    method: 'get'
  })
}

/**
 * 退出登录
 */
export const requestLogout = () => {
  return request({
    url: '/auth/logout',
    method: 'post'
  })
}

/**
 * 账号登录
 * @param data
 * @returns {AxiosPromise}
 */
export const requestAccountLogin = (data) => {
  return request({
    url: '/auth/accountLogin',
    method: 'post',
    data
  })
}

/**
 * 手机号登录
 * @param data
 * @returns {AxiosPromise}
 */
export const requestMobileLogin = (data) => {
  return request({
    url: '/auth/mobileLogin',
    method: 'post',
    data
  })
}

/**
 * 检验微信登录
 */
export const requestUserLogin = (data) => {
  return request({
    url: `/auth/getUserLogin/${data}`,
    method: 'get'
  })
}

/**
 * 用户注册
 */
export const reqRegister = (data) => {
  return request({
    url: '/auth/register',
    method: 'post',
    data
  })
}
/**
 * 初始化微信公众号二维码
 */
export const requestWechatQrCode = () => {
  return request({
    url: '/auth/getWeChatQrCode',
    method: 'get'
  })
}

/**
 * 发送注册验证码
 */
export const reqSmsCaptcha = (data) => {
  return request({
    url: '/captcha/getSmsCaptcha',
    method: 'post',
    data
  })
}

/**
 * 请求用户详情
 */
export const requestUserDetail = () => {
  return request({
    url: '/auth/getUserDetail',
    method: 'get'
  })
}

/**
 * 用户绑定手机号
 */
export const reqBindMobile = (data) => {
  return request({
    url: '/auth/bindMobile',
    method: 'post',
    data
  })
}
