import request from '@/utils/request'

/**
 * 获取我的订单
 * @param data
 * @returns {AxiosPromise}
 */
export const requestPage = (data) => {
  return request({
    url: '/order/page',
    method: 'post',
    data
  })
}

/**
 * 新增订单
 */
export const reqAddOrder = (data) => {
  return request({
    url: '/order/addAddOrder',
    method: 'post',
    data
  })
}

/**
 *  取消订单
 */
export const requestCancelOrder = (orderNo) => {
  return request({
    url: `/order/cancelOrder/${orderNo}`,
    method: 'get'
  })
}

/**
 * 获取订单状态
 */
export const requestOrderStatus = (orderNo) => {
  return request({
    url: `/order/getOrderStatus/${orderNo}`,
    method: 'get'
  })
}

export const requestListOrders = (data) => {
  return request({
    url: '/order/listOrders',
    method: 'post',
    data
  })
}
