import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/layout'
import Console from '@/console'
import Me from '@/me'

export const constantRoutes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'index',
        component: () => import('@/views/index')
      },
      {
        path: '/comment',
        name: 'comment',
        component: () => import('@/views/comment')
      },
      {
        path: '/member',
        name: 'member',
        component: () => import('@/views/member')
      },
      {
        path: '/blog',
        name: 'Blog',
        component: () => import('@/views/blog')
      },
      {
        path: '/chat',
        name: 'chat',
        component: () => import('@/views/chat')
      },
      {
        path: '/article/:id',
        name: 'Article',
        component: () => import('@/views/article')
      }
    ]
  },
  {
    path: '/me',
    component: Me,
    children: [
      {
        path: '/me',
        redirect: '/me/person/me-details'
      }
    ]
  }, {
    path: '/console',
    component: Console,
    children: [
      {
        path: '/console',
        redirect: '/console/home'
      }
    ]
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: constantRoutes
})

export default router
