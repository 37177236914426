import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { componentPlugin } from '@/components/index.js'

import '@/assets/styles/index.scss'
import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
// 中文语言
import locale from 'element-plus/es/locale/lang/zh-cn'
// 全局样式

// 导航守卫
import './permission'

// 插件
import plugins from './plugins'
// 分页组件
import Pagination from '@/components/Pagination'
import { getPictureShowUrl, parseTime, formatTime } from '@/utils/rzcode'
// 导入 svgIcon
import svgIcon from '@/plugins/svg-icon'
import elementIcons from '@/components/SvgIcon/svgicon'
// 代码高亮文件引入
import hljs from 'highlight.js'
// 样式文件,这里我选的是sublime样式，文件里面还有其他样式可供选择
import 'highlight.js/styles/monokai-sublime.css'
// markdown组件以及样式

const app = createApp(App)

// 自定义一个代码高亮指令
app.directive('highlight', function (el) {
  const blocks = el.querySelectorAll('pre code')
  blocks.forEach((block) => {
    hljs.highlightBlock(block)
  })
})

// 全局方法挂载
app.component('Pagination', Pagination)
app.config.globalProperties.getPictureShowUrl = getPictureShowUrl
app.config.globalProperties.parseTime = parseTime
app.config.globalProperties.formatTime = formatTime

app.use(ElementPlus, {
  locale: locale
})
app.use(plugins)
app.use(svgIcon)
app.use(elementIcons)
app.use(componentPlugin)

// // 在 Vue 实例创建之前，从本地存储中恢复 Vuex 数据
// const savedData = JSON.parse(localStorage.getItem('vuexData'))
// if (savedData) {
//   store.replaceState(savedData)
// }
//
// // 监听 Vuex 的变化，将数据保存到本地存储
// store.subscribe((mutation, state) => {
//   localStorage.setItem('vuexData', JSON.stringify(state))
// })

app.use(store).use(router).mount('#app')
