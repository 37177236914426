<template>
  <div class="yun-menu-system">
    <div
      v-for="(item, index) in systems"
      :key="index"
      class="box-item"

    >
      <div
        :class="systemName === item.icon ? 'item act' : 'item'"
        @click="clickHandle(item)"
      >
        <div class="img-container animation05">
          <img class="yun-iconfont normol" :src="require('../../../assets/images/systemIcon/'+item.icon+'-act.png')">
          <img class="yun-iconfont hover" :src="require('../../../assets/images/systemIcon/'+item.icon+'.png')">
        </div>
        <div class="text" v-text="item.name" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SystemSelect',
  components: {},
  props: {
    systems: {
      type: Array,
      default() {
        return []
      }
    },
    systemName: {
      type: String,
      default: ''
    }
  },
  emits: ['system-change'],
  data() {
    return {
      ossUrl: 'https://prod-ylzapp-public.oss-cn-zhangjiakou.aliyuncs.com/frontend/yun-design/yunDesign-system-icon-v2/'
    }
  },
  computed: {},
  methods: {
    dotShow({ count, oneMenuClassName }) {
      return count && oneMenuClassName === 'is-top' && this.isCollapse
    },
    clickHandle(item) {
      this.$emit('system-change', item)
    }
  }
}
</script>
<style lang="scss" scoped>
.yun-menu-system {
  position:fixed;
  left:0;
  top:50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y:scroll;
  float: left;
  padding: 8px;
  background: #F7F7F7;
  border-right: 1px solid #E6EAF0;
  box-sizing: border-box;
  background: #F0F2F5;
  &::-webkit-scrollbar {
    display: none;
  }
  .item {
    width: 48px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: #404040;
    border-radius: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    .img-container{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background: #fff;
      border-radius: 8px;
      img{
        height: 40%;
        width: auto;
      }
    }
    &.act {
      color: #0AD8A7;
      .img-container{
        background: #0AD8A7;
      }
      .yun-iconfont.normol {
        display: none;
      }
      .yun-iconfont.hover {
        display: block;
      }
    }
    &:hover {
      .img-container{
        background-color: #0AD8A7;
      }
      // color: var(--el-color-primary);
      // background: rgba(5, 158, 132, .08);
      .yun-iconfont.normol {
        display: none;
      }
      .yun-iconfont.hover {
        display: block;
      }
    }
    .yun-iconfont {
      width: 24px;
      height: 24px;
      font-size: 24px;
      &.hover {
        display: none;
      }
    }
    .text {
      line-height: 16px;
      font-size: 20px;
      transform: scale(0.5);
      width: 200px;
      margin-top: 5px;
    }
  }

}
</style>
<style lang="scss">
.yun-menu-system_popper{
    span{
      font-size: 14px;
      font-weight: 500;
    }
  }
</style>
