<template>
  <el-dialog
    :model-value="show"
    width="1200px"
    class="login-dialog-box"
    :before-close="beforeClose"
  >
    <div style="padding: 20px">
      <el-select
        placeholder="请选择我的酒店"
        v-model="hotelId"
        @change="changeBossHotel"
        filterable
        style="width: 100%"
      >
        <el-option
          v-for="item in bossHotels"
          :key="item.hotelId"
          :label="item.hotelName"
          :value="item.hotelId"
        />
      </el-select>
    </div>
    <div class="price">
      <div class="l">

        <img class="head" :src="require('../../assets/images/price/price_vip.png')" alt="">
        <b class="p1">标准版</b>
        <img class="hr" :src="require('../../assets/images/price/price_hr.png')" alt="">
        <div class="p2">会员尊享
          <img class="price8" :src="require('../../assets/images/price/price8.png')" alt="">
          <img class="priceMore" :src="require('../../assets/images/price/priceMore.png')" alt="">项特权
        </div>
        <div class="list">
          <div class="item" :key="ind" v-for="(item,ind) in equityList">
            <i>√</i>
            {{ item }}
          </div>
        </div>
      </div>

      <div class="r">
        <div class="title">购买时长</div>

        <div class="prices">
          <div class="item animation03" :class="item.value === active?'act':''" :key="ind" v-for="(item,ind) in prices"
               @click="handleTab(item)">

            <div class="p1">{{ item.time }}</div>
            <div class="p2">{{ item.price }}</div>
            <div class="p3">{{ item.originalPrice }}</div>
            <div class="bt animation03">{{ item.dayPrice }}</div>
            <img class="bg" :src="require('../../assets/images/price/price_bg.png')" alt="">
          </div>
        </div>
        <div class="title" style="margin-top:40px">微信扫码支付</div>
        <div class="main">
          <div class="ewm">
            <qr-code ref="qrCode1" @on-pay-end="onPayEnd" v-if="active === 'A'" :fee="prices[0].price" combo="A" :hotelId="hotelId"/>
            <qr-code ref="qrCode2" @on-pay-end="onPayEnd" v-else-if="active === 'B'" :fee="prices[1].price" combo="B" :hotelId="hotelId"/>
            <qr-code ref="qrCode3" @on-pay-end="onPayEnd" v-else-if="active === 'C'" :fee="prices[2].price" combo="C" :hotelId="hotelId"/>
            <qr-code ref="qrCode4" @on-pay-end="onPayEnd" v-else :fee="prices[3].price" combo="D" :hotelId="hotelId"/>
          </div>
          <div class="price">¥{{ actPrice.price }}</div>
        </div>

      </div>
    </div>
  </el-dialog>
</template>
<script setup>
import {
  ElDialog,
  ElTabs,
  ElTabPane,
  ElButton,
  ElForm,
  ElFormItem,
  ElInput,
  ElCheckbox,
  ElMessage,
  ElSelect,
  ElOption
} from 'element-plus'
import QrCode from './QrCode'
import { reactive, ref } from 'vue'
import { getBossHotels } from '@/utils/hotel'
import { reqListBossHotels } from '@/api/hotel'

const props = defineProps({
  hotelId: {
    type: Number,
    default: undefined
  },
  confirm: {
    type: Function,
    default: (fun) => fun()
  },
  callback: {
    type: Function,
    default: (fun) => fun()
  }
})
const bossHotels = getBossHotels()
const hotelId = ref(props.hotelId)
const show = ref(true)
const active = ref('A')
const qrCode1 = ref(null)
const qrCode2 = ref(null)
const qrCode3 = ref(null)
const qrCode4 = ref(null)
const prices = [
  { time: '12个月', price: 0.1, originalPrice: '¥980', dayPrice: '低至2.7元/天', value: 'D' },
  { time: '6个月', price: 0.1, originalPrice: '¥580', dayPrice: '低至3.3元/天', value: 'C' },
  { time: '3个月', price: 0.1, originalPrice: '¥380 ', dayPrice: '低至4.2元/天', value: 'B' },
  { time: '连续包月', price: 0.1, originalPrice: '¥180', dayPrice: '低至19.9元/天', value: 'A' }
]
const actPrice = reactive({
  price: prices[0].price,
  value: 'A'
})

const changeBossHotel = (val) => {
  hotelId.value = val
}

const handleTab = (row) => {
  active.value = row.value
  Object.assign(actPrice, { price: row.price, value: row.value })
}
// 关闭前去掉订单支付状态轮询
const beforeClose = (done) => {
  qrCode1.value && qrCode1.value.clearTimer()
  qrCode2.value && qrCode2.value.clearTimer()
  qrCode3.value && qrCode3.value.clearTimer()
  qrCode4.value && qrCode4.value.clearTimer()
  done()
}

const equityList = ref(['点评运营',
  '价格运营',
  'AI图片',
  '归零码招聘',
  '归零码',
  '群郗PMS',
  '行业互联 '])

// 支付成功
const onPayEnd = async () => (props.callback(), show.value = false)

</script>

<style lang="scss" scoped>
.price {
  display: flex;

  img {
    display: inline-block;
  }

  .l {
    width: 300px;
    background: linear-gradient(to bottom, #0AD8A7, #C8F0E7, #F6F6F6);
    display: flex;
    flex-direction: column;
    align-items: center;

    .head {
      width: 20%;
      margin-top: 40px;
    }

    .p1 {
      font-size: 20px;
      margin-top: 10px;
      color: #5E5E5E;
    }

    .hr {
      width: 50%;
      margin-top: 20px;
    }

    .p2 {
      margin-top: 10px;
      display: flex;
      align-items: center;
      color: #5E5E5E;
      font-weight: bold;

      img {
        // margin: 0 10px;
      }

      .price8 {
        height: 60%;
        margin-left: 5px
      }

      .priceMore {
        height: 10px;
        transform: translateY(-10px);
        margin-right: 5px;
      }
    }

    .list {
      margin-top: 20px;
      text-align: center;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 20px;

      .item {
        line-height: 32px;
        width: 75px;
        text-align: left;

        img {
          height: 18px;
          color: #7E8694;
        }

        i {
          color: #0AD8A7;
          margin-right: 5px;
        }
      }

    }
  }

  .r {
    flex-grow: 1;
    padding: 0 20px;
    box-sizing: border-box;

    .title {
      height: 24px;
      line-height: 24px;
      margin-bottom: 32px 0;
      position: relative;
      font-size: 16px;
      font-weight: bold;
      color: #5E5E5E;
      padding-left: 10px;
      margin-bottom: 32px;

      &::before {
        content: ' ';
        width: 6px;
        height: 100%;
        position: absolute;
        left: -6px;
        top: 0;
        background: #0AD8A7;
      }
    }

    .prices {
      display: flex;

      .item {
        border: 1px solid #BCEADE;
        border-radius: 10px;
        box-shadow: #00c091;
        text-align: center;
        padding: 20px 50px 52px;
        margin-right: 16px;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &.act {
          border: 1px solid #FEB633;
          background: #FFF4EA;

          .bt {
            background: #E8A86D;
            color: #fff;
          }
        }

        &:hover {
          border: 1px solid #FEB633;

          .bt {
            background: #E8A86D;
            color: #fff;
          }
        }

        .p1 {
          font-size: 18px;
          font-weight: bold;
          color: #5E5E5E;
        }

        .p2 {
          font-size: 38px;
          font-weight: bold;
          color: #5E5E5E;
          line-height: 40px;
        }

        .p3 {
          font-size: 12px;
          color: #B7B7B7;
          margin-top: 10px;
          text-decoration: line-through;
        }

        .bt {
          height: 32px;
          line-height: 32px;
          text-align: center;
          background: #C8FFF2;
          position: absolute;
          bottom: 0;
          width: 100%;
          left: 0;
          color: #0AD8A7;
        }

        .bg {
          position: absolute;
          width: 60%;
          top: 0;
          left: 20%;

        }
      }
    }

    .main {
      padding-bottom: 30px;

      .ewm {
        border-radius: 10px;
        border: 1px solid #E1E1E1;
        padding: 20px;
        width: 260px;
        box-sizing: border-box;

        img {
          width: 100%;
        }
      }

      .price {
        font-size: 24px;
        width: 260px;
        color: #EC5A4C;
        font-weight: bold;
        text-align: center;
        margin-top: 10px;
        text-align: center;
        justify-content: center;
      }
    }
  }
}

.login-dialog-form-box {
  position: relative;
  background: url('../../assets/images/login_bg.png');
  background-size: 100% 100%;

  .close-loding-dialog-box {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 999;
    cursor: pointer;
  }

  h1 {
    font-weight: bold;
    margin: 0;
  }

  h2 {
    text-align: center;
  }

  p {
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 0 30px;
  }

  span {
    font-size: 12px;
  }

  a {
    color: #333;
    font-size: 14px;
    text-decoration: none;
    margin: 0 0 15px 0;
  }

  button {
    border-radius: 20px;
    border: 1px solid #00c091;
    background-color: #00c091;
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    padding: 18px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
    cursor: pointer;
  }

  button:active {
    transform: scale(0.95);
  }

  button:focus {
    outline: none;
  }

  button.ghost {
    background-color: transparent;
    border-color: #ffffff;
    cursor: pointer;
    padding: 12px 45px;
  }

  .container {
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
  }

  .form-container {
    position: absolute;
    background: white;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 75px;

    :deep(.forms_form) {
      width: 100%;

      input {
        border: none;
        padding: 10px 12px;
        margin: 8px 0;
        width: 100%;
        font-size: 14px;
        color: #333;
      }

      input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px #EBF2F5 inset !important;
      }

      .el-form-item {
        margin-bottom: 20px;
      }

      .el-input__inner {
        background-color: none !important;
      }
    }
  }

  .el-button.forms_buttons-action {
    /* 添加以下样式 */
    margin-top: 10px;
    align-self: center;
    width: 100%;
    background: #0AD8A7;
    padding: 0;
    height: 40px;
    line-height: 40px;
    border-radius: 8px;
    transition: all .5s;

    &:hover {
      background: #32AF9A;
    }
  }

  .sign-in-container {
    left: 0;
    width: 65%;
    z-index: 2;

    .local-login-container {
      width: 100%;
      /* 添加以下样式 */
      display: flex;
      flex-direction: column;
      align-items: center;

      .horizontal-layout {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left-align {
          text-align: left;
          flex: 1;
        }

        .right-align {
          text-align: right;
          flex: 1;
        }
      }
    }

    .third-login-container {
      position: relative;;
      width: 220px;
      height: 220px;
      overflow: hidden;

      img {
        max-width: 90%;
        max-height: 90%;
      }

      .scan-fail {
        width: 100%;
        height: 100%;
        font-size: 16px;
        letter-spacing: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.2);
        -webkit-backdrop-filter: blur(8px);
        backdrop-filter: blur(8px);
        box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.2);
      }
    }

    .additional-login-title {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 15px;

      .line {
        flex-grow: 1;
        height: 1px;
        background-color: #ccc;
      }

      span {
        margin: 0 10px;
      }
    }

    .additional-login-btn {
      margin-top: 15px;
    }

    .centered-link {
      display: flex;
      align-items: center;
    }

    .link-text {
      vertical-align: middle;
    }

  }

  .sign-up-container {
    left: 0;
    width: 65%;
    opacity: 0;
    z-index: 1;
  }

  .container.right-panel-active {
    .sign-in-container {
      &:nth-child(2) {
        display: none;
      }
    }
  }

  .container.right-panel-active .sign-up-container {
    left: 35%;
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
    background-color: #fff;
  }

  @keyframes show {
    0%,
    49.99% {
      opacity: 0;
      z-index: 1;
    }

    50%,
    100% {
      opacity: 1;
      z-index: 5;
    }
  }

  .overlay-container {
    position: absolute;
    top: 0;
    left: 65%;
    width: 35%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
  }

  .container.right-panel-active .overlay-container {
    left: 0;
  }

  .overlay {
    // background: #b0db7d;
    // background: linear-gradient(to bottom right, #73b722 40%, #9dc6ae 100%);
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: 0 0;
    color: #ffffff;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
  }

  .container.right-panel-active .overlay {
    transform: translateX(50%);
  }

  .overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    // justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    padding-top: 80px;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
    background: url('../../assets/images/login_logo.png') center 440px no-repeat;
    background-size: 120px 24px;

    h1 {
      font-weight: normal;
    }
  }

  .overlay-left {
    transform: translateX(-20%);
  }

  .container.right-panel-active .overlay-left {
    transform: translateX(0);
  }

  p {
    margin: 0;
    padding: 0;
  }

  .stt {
    font-size: 14px;
    color: #7E8694;
    margin-top: 10px;
    font-weight: 500;

    span {
      color: #0AD8A7;
      cursor: pointer;
      font-size: 14px;
    }
  }

  .overlay-right {
    right: 0;
    transform: translateX(0);
  }

  .container.right-panel-active .overlay-right {
    transform: translateX(20%);
  }

  .social-container {
    margin: 20px 0;
  }

  :deep .el-input__wrapper {
    background: #EBF2F5 !important;
    border-radius: 8px;
    height: 40px;
    line-height: 40px;
  }

  :deep .el-input-group__append {
    height: 40px;
    line-height: 40px;
    border-radius: 8px;
    background: white !important;
    margin-left: 10px;
    border: 1px solid #CDD2DA;
    box-shadow: 0 0 0 0;
    cursor: pointer;

    &:hover {
      border: 1px solid #059E84;
      background: #E6F5F3;
      color: #059E84;
    }
  }
}

// :deep(.el-input__inner:focus){
//   background: #EBF2F5  !;
// }
</style>
<style lang="scss">
.login-dialog-box {
  border-radius: 16px !important;
  overflow: hidden;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
    min-height: 480px;
  }
}
</style>
