import Cookies from 'js-cookie'
import { tokenConfig } from '@/config/network.config'

const TokenKey = tokenConfig.header

export const getToken = () => {
  return Cookies.get(TokenKey)
}

export const setToken = (token) => {
  return Cookies.set(TokenKey, token, { expires: tokenConfig.expireTime })
}

export const removeToken = () => {
  return Cookies.remove(TokenKey)
}
