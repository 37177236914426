<template>
  <nav-bar @updateRouterView="updateRouterView"/>
  <div :class="clazz" class="app-wrapper">
    <Systems
      v-if="systems.length > 0"
      :systems="systems"
      :system-name="systemName"
      @system-change="onSystemChange"

    />
    <side-bar class="sidebar-container" ref="sidebarRef"/>
    <div class="main-container">
      <section class="section-main">
        <router-view  v-loading="showRouterViewLoading" v-if="showRouterView" />
      </section>
    </div>
  </div>
</template>
<script setup>
import { computed, getCurrentInstance, nextTick, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import SideBar from './components/SideBar'
import NavBar from './components/NavBar'
import Systems from '@/console/components/SideBar/Systems.vue'

const showRouterView = ref(true)
const showRouterViewLoading = ref(false)
const route = useRoute()

const updateRouterView = () => {
  console.log('change')
  showRouterView.value = false
  showRouterViewLoading.value = true
  nextTick(() => {
    showRouterView.value = true
    const timer = setTimeout(() => {
      showRouterViewLoading.value = false
      clearTimeout(timer)
    }, 1000)
  })
}

const { proxy } = getCurrentInstance()
const systemName = ref(route.path.indexOf('compare-price') !== -1 ? 'biJXT' : 'dianPXT')
const systems = [
  {
    icon: 'dianPXT',
    name: '点评系统',
    value: 'comment',
    describe: '点评系统'
  },
  {
    icon: 'biJXT',
    name: '比价系统',
    value: 'compare',
    describe: '比价系统'
  }
]
const onSystemChange = (row) => (proxy.$refs.sidebarRef.change(row.value), systemName.value = row.icon)

const store = useStore()
const sidebarCfg = computed(() => store.getters['app/sidebar'])
const clazz = computed(() => ({
  hideSidebar: !sidebarCfg.value.opened,
  openSidebar: sidebarCfg.value.opened,
  withoutAnimation: sidebarCfg.value.withoutAnimation
}))

</script>

<style lang="scss" scoped>
@import '@/assets/styles/mixin.scss';
@import '@/assets/styles/variables.module.scss';
@import '@/assets/styles/sidebar.scss';

.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;
}

.section-main {
  padding: 16px 24px;
  background-color: #F6F6F6;
}
:deep(.el-loading-spinner){
  margin-top: 0;
  top:300px
}

</style>
