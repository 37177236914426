<template>
  <div class="call-me-box">
    <el-popover popper-class="vxqun-popover-box" trigger="hover" placement="left" teleported>
      <template #reference>
        <span class="contact-me">
          <svg-icon icon-name="wechat" style="height: 35px; width: 20px"/>
        </span>
      </template>
      <!-- 内容区域 -->
      <div class="vx-box">
        <div class="vx-title">
          <h1>添加微信</h1>
          <p>迅速解答疑问！</p>
        </div>
        <div class="vx-img">
          <img class="bgc-img" src="@/assets/images/code/img.png"/>
        </div>
      </div>
    </el-popover>
    <el-tooltip class="box-item" effect="light" content="问题或建议反馈" placement="left">
      <router-link to="/board" class="contact-me">
        <svg-icon icon-name="feedback" style="height: 35px; width: 20px"/>
      </router-link>
    </el-tooltip>
  </div>
</template>
<script setup>

</script>

<style lang="scss" scoped>
.call-me-box {
  position: fixed;
  right: 15px;
  top: 80%;
  transform: translate(0, -50%);
  display: flex;
  flex-direction: column;
  z-index: 10;

  .contact-me {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    background-image: -webkit-linear-gradient(to right, #2ddd9d, #1cc7cf);
    background-image: -moz-linear-gradient(to right, #2ddd9d, #1cc7cf);
    background-image: -ms-linear-gradient(to right, #2ddd9d, #1cc7cf);
    background-image: linear-gradient(to right, #2ddd9d, #1cc7cf);
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    background-color: #2ddd9d;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    cursor: pointer;
    user-select: none;
    margin-bottom: 10px;

    &:hover {
      opacity: 0.8;
    }
  }
}

.vx-box {
  width: 180px;
  height: 250px;

  .vx-title {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-size: 14px;
    }

    p {
      font-size: 12px;
      color: rgb(105, 105, 105);
      margin: 6px 0;
    }
  }

  .vx-img {
    width: 100%;
    overflow: hidden;

    .bgc-img {
      width: 180px;
      height: 180px;
      display: flex;
      text-align: center;
    }
  }
}
</style>
<style lang="scss">
.vxqun-popover-box {
  width: inherit !important;
}
</style>
