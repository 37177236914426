<template>
  <el-dialog
    :model-value="show"
    width="768px"
    class="guide-dialog"
  >
    <el-row type="flex" class="row-bg" justify="center">
      <el-col>
        <div class="bind_ht">
          <img class="animation03"  :src="require(`./../../../assets/images/platform/bdjd.png`)" @click="bindHotel">

          <el-tooltip class="item" effect="dark" content="请联系管理员,加入对应酒店!" placement="top-start">
          <img class="animation03" :src="require(`./../../../assets/images/platform/jrjd.png`)">
        </el-tooltip>
        </div>
        <!-- <el-button @click="bindHotel">绑定酒店</el-button>
        <el-tooltip class="item" effect="dark" content="请联系管理员,加入对应酒店!" placement="top">
          <el-button>加入酒店</el-button>
        </el-tooltip> -->
      </el-col>
    </el-row>
  </el-dialog>
</template>
<script setup>
import {
  ElDialog,
  ElRow,
  ElCol,
  ElButton,
  ElTooltip
} from 'element-plus'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const show = ref(true)

const visible = ref(false)

const props = defineProps({
  confirm: {
    type: Function,
    default: (fun) => fun()
  },
  callback: {
    type: Function,
    default: (fun) => fun()
  }
})

/**
 * 绑定酒店
 */
const bindHotel = () => (props.confirm(), props.callback())

</script>

<style lang="scss" scoped>
.login-dialog-form-box {
  background-size: 100% 100%;
}
.bind_ht{
  height: 250px;
  padding: 30px 0 60px;
  text-align: center;
  img{
    height: 100%;
    margin: 0 20px;
    cursor: pointer;

    &:hover{
      transform: translateY(-10px);
    }
  }
}
</style>
