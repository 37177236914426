import auth from '@/plugins/auth'
import { requestGetClientRouter } from '@/api/user'
import Me from '@/me/index'
import Console from '@/console/index'
import { constantRoutes } from '@/router'

const getters = {
  routes: (state) => state.routes,
  addRoutes: (state) => state.addRoutes,
  generated: (state) => state.generated
}

const state = {
  routes: [],
  addRoutes: [],
  generated: false
}

const mutations = {
  setRouters: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  },
  setGenerated: (state, generated) => {
    state.generated = generated
  }
}

const actions = {
  // 生成路由
  async generatorRouters({ commit, state }) {
    // 向后端请求路由数据
    const { code, data } = await requestGetClientRouter()
    if (code === 200) {
      if (data !== null && data.size !== 0) {
        const routers = filterAsyncRouter(JSON.parse(JSON.stringify(data)))
        commit('setRouters', routers)
        commit('setGenerated', true)
      }
    }
  }
}

// 遍历后台传来的路由字符串，转换为组件对象
const filterAsyncRouter = (asyncRouterMap, lastRouter = false, type = false) => {
  return asyncRouterMap.filter((route) => {
    if (type && route.children) {
      route.children = filterChildren(route.children)
    }
    if (route.component) {
      // Layout ParentView 组件特殊处理
      if (route.component === 'Console') {
        route.component = Console
      } else if (route.component === 'Person') {
        route.component = Me
      } else {
        route.component = loadView(route.component)
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children, route, type)
    } else {
      delete route.children
      delete route.redirect
    }
    return true
  })
}

const filterChildren = (childrenMap, lastRouter = false) => {
  let children = []
  childrenMap.forEach((el, index) => {
    if (el.children && el.children.length) {
      if (el.component === 'ParentView' && !lastRouter) {
        el.children.forEach((c) => {
          c.path = el.path + '/' + c.path
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c))
            return
          }
          children.push(c)
        })
        return
      }
    }
    if (lastRouter) {
      el.path = lastRouter.path + '/' + el.path
    }
    children = children.concat(el)
  })
  return children
}

/**
 * 动态路由遍历，验证是否具备权限
 */
export const filterDynamicRoutes = (routes) => {
  const res = []
  routes.forEach((route) => {
    if (route.permissions) {
      if (auth.hasPermiOr(route.permissions)) {
        res.push(route)
      }
    } else if (route.roles) {
      if (auth.hasRoleOr(route.roles)) {
        res.push(route)
      }
    }
  })
  return res
}

// 路由懒加载
export const loadView = (view) => {
  return () => require.ensure([], (require) => require(`@/views/${view}`))
}

export default { namespaced: true, state, getters, mutations, actions }
