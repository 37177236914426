<template>
  <el-dialog
    :model-value="show"
    width="768px"
    class="login-dialog-box"
  >
    <div class="login-dialog-form-box">
      <div :class="['container', { 'right-panel-active': isSignUp }]">
        <div class="form-container sign-up-container">
          <h1>创建账号</h1>
          <div class="social-container"/>
          <el-form ref="registerFormRef" class="forms_form" :model="registerForm" :rules="registerRules">
            <el-form-item prop="mobile">
              <el-input
                v-model="registerForm.mobile"
                type="input"
                class="forms_field-input"
                maxlength="40"
                placeholder="手机号"
              />
            </el-form-item>
            <!-- 验证码 -->
            <el-form-item prop="captcha">
              <el-input
                v-model="registerForm.captcha"
                type="input"
                class="forms_field-input"
                placeholder="验证码"
              >
                <template #append>
                  <div class="centered-link">
                    <span v-if="registerCountDown > 0" class="link-text">{{ registerCountDown }}s</span>
                    <span v-else class="link-text" @click="startCountdown('reg')">{{ btnTxt }}</span>
                  </div>
                </template>
              </el-input>
            </el-form-item>
            <!-- 密码 -->
            <el-form-item prop="password">
              <el-input
                v-model="registerForm.password"
                type="password"
                class="forms_field-input"
                placeholder="密码"
              />
            </el-form-item>
          </el-form>
          <el-button
            style="margin-top: 10px"
            class="forms_buttons-action"
            :loading="isRegisterLoading"
            @click="register(registerFormRef)"
          >
            注 册
          </el-button>
        </div>
        <div class="form-container sign-in-container">
          <h1 style="width:100%;font-weight:normal;color:#000">登录到<br>归零码应用平台</h1>
          <div class="social-container"/>
          <div v-if="!isThirdLogin" class="local-login-container">
            <el-form ref="loginRuleFormRef" class="forms_form" :model="loginForm" :rules="loginRules">
              <el-form-item prop="account" v-if="!isSmsLogin">
                <el-input
                  v-model="loginForm.username"
                  class="forms_field-input"
                  maxlength="40"
                  placeholder="账号或手机号"
                />
              </el-form-item>
              <el-form-item prop="password" v-if="!isSmsLogin">
                <el-input
                  v-model="loginForm.password"
                  type="password"
                  class="forms_field-input"
                  placeholder="密码"
                  show-password
                />
              </el-form-item>
              <el-form-item prop="mobile" v-if="isSmsLogin">
                <el-input
                  v-model="loginForm.mobile"
                  class="forms_field-input"
                  maxlength="40"
                  placeholder="手机号"
                />
              </el-form-item>
              <el-form-item prop="captcha" v-if="isSmsLogin">
                <el-input
                  v-model="loginForm.captcha"
                  type="input"
                  class="forms_field-input"
                  placeholder="验证码"
                >
                  <template #append>
                    <div class="centered-link">
                      <span v-if="loginCountDown > 0" class="link-text">{{ loginCountDown }}s</span>
                      <span v-else class="link-text" @click="startCountdown('login')">{{ btnTxt }}</span>
                    </div>
                  </template>
                </el-input>
              </el-form-item>
            </el-form>
            <div class="horizontal-layout">
              <div class="left-align">
                <el-checkbox v-model="checked">下次自动登陆</el-checkbox>
              </div>
              <div class="right-align">
                <a style="font-size: 14px;" @click="toggleLoginType">{{ loginTypeTxt }}</a>
                <span style="font-size: 14px;">&nbsp;|&nbsp;</span>
                <a style="font-size: 14px;color:#0AD8A7">忘记密码</a>
              </div>
            </div>
            <el-button
              style="margin-top: 10px"
              class="forms_buttons-action"
              :loading="isLoginLoading"
              @click="auth(loginRuleFormRef)">
              登 录
            </el-button>
          </div>
          <div v-if="isThirdLogin" class="third-login-container">
            <div v-if="isBindMobile">
              <img class="bgc-img" :src="weChatQrCodeURL"/>
              <div v-if="scanFail" class="scan-fail">
                <span class="link-text" @click="getWeChatQrCode">重新刷新</span>
              </div>
            </div>
            <div v-if="!isBindMobile">
              <el-form ref="bindFormRef" class="forms_form" :model="bindForm" :rules="bindRules">
                <el-form-item prop="mobile">
                  <el-input
                    v-model="bindForm.mobile"
                    class="forms_field-input"
                    maxlength="40"
                    placeholder="手机号"
                  />
                </el-form-item>
                <el-form-item prop="captcha">
                  <el-input
                    v-model="bindForm.captcha"
                    type="input"
                    class="forms_field-input"
                    placeholder="验证码"
                  >
                    <template #append>
                      <div class="centered-link">
                        <span v-if="bindCountDown > 0" class="link-text">{{ bindCountDown }}s</span>
                        <span v-else class="link-text" @click="startCountdown('bind')">{{ btnTxt }}</span>
                      </div>
                    </template>
                  </el-input>
                </el-form-item>
                <el-button
                  style="margin-top: 10px"
                  class="forms_buttons-action"
                  :loading="isBindLoading"
                  @click="bind(bindFormRef)"
                >
                  绑定
                </el-button>
              </el-form>
            </div>
          </div>

          <!--其他方式登录-->
          <div class="additional-login-title">
            <div class="line"/>
            <span>通过其他方式登录</span>
            <div class="line"/>
          </div>
          <div class="additional-login-btn">
            <div>
              <div v-if="!isThirdLogin" @click="toggleThirdLogin">
                <svg-icon icon-name="wechat" style="height: 35px; width: 20px;color:#1AAD19"/>
              </div>
              <div v-if="isThirdLogin">
                <a @click="toggleSmsLogin">短信登录</a>｜<a @click="togglePwdLogin">密码登录</a>
              </div>
            </div>
          </div>
        </div>
        <!-- 注册登录浮窗切换 -->
        <div class="overlay-container">
          <div class="overlay">
            <div class="overlay-panel overlay-left">
              <h1 style="width:100%;color:#1C2026;font-size:32px">欢迎回来</h1>
              <p class="stt">已有帐号 <span @click="signIn">立即登录</span></p>
            </div>
            <div class="overlay-panel overlay-right">
              <h1 style="width:100%;color:#1C2026;font-size:32px">欢迎回来</h1>
              <p class="stt">还没有账号 <span @click="signUp">立即注册</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script setup>
import {
  ElDialog,
  ElButton,
  ElForm,
  ElFormItem,
  ElInput,
  ElCheckbox,
  ElMessage
} from 'element-plus'
import svgIcon from '@/components/SvgIcon'
import { reactive, ref, watch } from 'vue'
import {
  requestAccountLogin,
  requestMobileLogin,
  requestUserLogin,
  reqRegister,
  requestWechatQrCode,
  reqSmsCaptcha,
  reqBindMobile
} from '@/api/auth'

const props = defineProps({
  isLogin: {
    type: Boolean,
    default: false
  },
  confirm: {
    type: Function,
    default: (fun) => fun()
  },
  callback: {
    type: Function,
    default: (fun) => fun()
  }
})
const show = ref(true)
const loginRuleFormRef = ref()
const registerFormRef = ref()
const bindFormRef = ref()
const isSignUp = ref(!props.isLogin)
const isLoginLoading = ref(false)
const isRegisterLoading = ref(false)
const isBindLoading = ref(false)
const checked = ref(true)
const btnTxt = ref('获取验证码')
const isSmsLogin = ref(false)
const loginTypeTxt = ref('短信登录')
const isThirdLogin = ref(false)
const loginCountDown = ref(0)
const registerCountDown = ref(0)
const bindCountDown = ref(0)
const weChatQrCodeURL = ref('')
const scanFail = ref(false)
const loopGetUserLogin = ref(false)
const isBindMobile = ref(true)

const signUp = () => {
  scanFail.value = false
  isSignUp.value = true
}
const signIn = () => {
  scanFail.value = false
  isSignUp.value = false
}
const loginForm = reactive({
  username: '',
  mobile: '',
  password: '',
  captcha: '',
  hasCaptcha: 'N'
})
const registerForm = reactive({
  mobile: '',
  captcha: '',
  password: ''
})

const bindForm = reactive({
  mobile: '',
  captcha: ''
})

const loginRules = reactive({
  username: [
    {
      required: true,
      message: '请输入账号!',
      trigger: 'change'
    }
  ],
  mobile: [
    {
      required: true,
      message: '请输入手机号!',
      trigger: 'change'
    }
  ],
  password: [
    {
      required: true,
      message: '请输入密码!',
      trigger: 'change'
    }
  ],
  captcha: [
    {
      required: true,
      message: '请输入验证码!',
      trigger: 'change'
    }
  ]
})

const registerRules = reactive({
  mobile: [
    {
      required: true,
      message: '请输入手机号!',
      trigger: 'change'
    }
  ],
  captcha: [
    {
      required: true,
      message: '请输入验证码!',
      trigger: 'change'
    }
  ],
  password: [
    {
      required: true,
      message: '请输入密码!',
      trigger: 'change'
    }
  ]
})

/**
 * 开始发送短信验证码
 */
const startCountdown = async (optType) => {
  const countdown = ref(60)
  let mobile
  if (optType === 'login') {
    mobile = loginForm.mobile
  }
  if (optType === 'reg') {
    mobile = registerForm.mobile
  }
  if (optType === 'bind') {
    mobile = bindForm.mobile
  }
  const { code, message } = await reqSmsCaptcha({
    mobile: mobile,
    optType: optType
  })
  if (code === 200) {
    ElMessage({ message: message, type: 'success', duration: 1 * 1000 })
    const timer = setInterval(() => {
      if (countdown.value > 0) {
        countdown.value--
      } else {
        clearInterval(timer)
      }
    }, 1000)
    // 监听倒计时变化
    watch(countdown, (newValue) => {
      if (optType === 'login') {
        loginCountDown.value = newValue
      }
      if (optType === 'reg') {
        registerCountDown.value = newValue
      }
      if (optType === 'bind') {
        bindCountDown.value = newValue
      }
    })
  }
}

/**
 * 用户登录
 */
const auth = async (formEl) => {
  if (!formEl) return

  const valid = await formEl.validate()
  if (!valid) return
  isLoginLoading.value = true

  try {
    let requestFunc
    if (isSmsLogin.value) {
      requestFunc = requestMobileLogin
    } else {
      requestFunc = requestAccountLogin
    }
    const { code, data } = await requestFunc(loginForm)
    if (code === 200) {
      props.callback(data.token)
      props.confirm()
    }
  } catch (error) {
    // 处理请求失败或其他错误的逻辑
    console.log(error)
  } finally {
    isLoginLoading.value = false
  }
}
/**
 * 用户注册
 */
const register = async (formEl) => {
  if (!formEl) return

  const valid = await formEl.validate()
  if (!valid) return

  isRegisterLoading.value = true

  try {
    const { code } = await reqRegister(registerForm)
    if (code === 200) {
      isSignUp.value = false
    } else {
      // 处理其他响应状态码的逻辑
    }
  } catch (error) {
    // 处理请求失败或其他错误的逻辑
  } finally {
    isRegisterLoading.value = false
  }
}
/**
 * 用户绑定手机
 */
const bind = async (formEl) => {
  if (!formEl) return

  const valid = await formEl.validate()
  if (!valid) return

  isRegisterLoading.value = true
  try {
    const { code } = await reqBindMobile(bindForm)
    if (code === 200) {
      props.confirm()
    }
  } catch (error) {
    // 处理请求失败或其他错误的逻辑
  } finally {
    isRegisterLoading.value = false
  }
}
/**
 * 切换登录类型
 */
const toggleLoginType = async () => {
  isSmsLogin.value = !isSmsLogin.value
  loginTypeTxt.value = isSmsLogin.value ? '密码登录' : '短信登录'
  scanFail.value = false
}

/**
 * 切换到短信登录
 */
const toggleSmsLogin = async () => {
  isThirdLogin.value = false
  isSmsLogin.value = true
  scanFail.value = false
  loopGetUserLogin.value = false
}

/**
 * 切换到密码登录
 */
const togglePwdLogin = async () => {
  isThirdLogin.value = false
  isSmsLogin.value = false
  scanFail.value = false
  loopGetUserLogin.value = false
}

/**
 * 切换三方登录
 */
const toggleThirdLogin = async () => {
  isThirdLogin.value = !isThirdLogin.value
  scanFail.value = false
  getWeChatQrCode().then(err => console.log(err))
}

/**
 * 获取用户信息
 */
const getUserLogin = async (val) => {
  try {
    const { code, data: { token, hasMobile } } = await requestUserLogin(val)
    if (code === 200 && token !== null) {
      props.callback(token)
      if (hasMobile === 'N') {
        isBindMobile.value = !isBindMobile.value
        return true
      }
      props.confirm()
    }
  } catch (err) {
    console.log(err)
  } finally {
    isLoginLoading.value = false
  }
}

// 获取微信登录二维码
const getWeChatQrCode = async () => {
  scanFail.value = false
  const { code, data: { url, eventKey } } = await requestWechatQrCode()
  if (code === 200) {
    weChatQrCodeURL.value = url
    loopGetUserLogin.value = true
    const timeout = 1 * 60 * 1000
    const timerId = setTimeout(() => {
      clearInterval(intervalId)
    }, timeout)
    const scanId = setTimeout(() => {
      scanFail.value = true
    }, timeout)
    const intervalId = setInterval(async () => {
      if (!loopGetUserLogin.value) {
        clearInterval(intervalId)
        return
      }
      if (await getUserLogin(eventKey)) {
        clearInterval(intervalId)
      }
    }, 1000)

    setTimeout(() => {
      clearInterval(intervalId)
      clearTimeout(timerId)
      clearInterval(scanId)
    }, timeout)
  }
}

</script>

<style lang="scss" scoped>
.login-dialog-form-box {
  position: relative;
  background: url('../../assets/images/login_bg.png');
  background-size: 100% 100%;

  .close-loding-dialog-box {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 999;
    cursor: pointer;
  }

  h1 {
    font-weight: bold;
    margin: 0;
  }

  h2 {
    text-align: center;
  }

  p {
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 0 30px;
  }

  span {
    font-size: 12px;
  }

  a {
    color: #333;
    font-size: 14px;
    text-decoration: none;
    margin: 0 0 15px 0;
  }

  button {
    border-radius: 20px;
    border: 1px solid #00c091;
    background-color: #00c091;
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    padding: 18px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
    cursor: pointer;
  }

  button:active {
    transform: scale(0.95);
  }

  button:focus {
    outline: none;
  }

  button.ghost {
    background-color: transparent;
    border-color: #ffffff;
    cursor: pointer;
    padding: 12px 45px;
  }

  .container {
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
  }

  .form-container {
    position: absolute;
    background: white;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 75px;

    :deep(.forms_form) {
      width: 100%;

      input {
        border: none;
        padding: 10px 12px;
        margin: 8px 0;
        width: 100%;
        font-size: 14px;
        color: #333;
      }

      input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px #EBF2F5 inset !important;
      }

      .el-form-item {
        margin-bottom: 20px;
      }

      .el-input__inner {
        background-color: none !important;
      }
    }
  }

  .el-button.forms_buttons-action {
    /* 添加以下样式 */
    margin-top: 10px;
    align-self: center;
    width: 100%;
    background: #0AD8A7;
    padding: 0;
    height: 40px;
    line-height: 40px;
    border-radius: 8px;
    transition: all .5s;

    &:hover {
      background: #32AF9A;
    }
  }

  .sign-in-container {
    left: 0;
    width: 65%;
    z-index: 2;

    .local-login-container {
      width: 100%;
      /* 添加以下样式 */
      display: flex;
      flex-direction: column;
      align-items: center;

      .horizontal-layout {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left-align {
          text-align: left;
          flex: 1;
        }

        .right-align {
          text-align: right;
          flex: 1;
        }
      }
    }

    .third-login-container {
      position: relative;;
      width: 220px;
      height: 220px;
      overflow: hidden;

      img {
        max-width: 90%;
        max-height: 90%;
      }

      .scan-fail {
        width: 100%;
        height: 100%;
        font-size: 16px;
        letter-spacing: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.2);
        -webkit-backdrop-filter: blur(8px);
        backdrop-filter: blur(8px);
        box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.2);
      }
    }

    .additional-login-title {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 15px;

      .line {
        flex-grow: 1;
        height: 1px;
        background-color: #ccc;
      }

      span {
        margin: 0 10px;
      }
    }

    .additional-login-btn {
      margin-top: 15px;
    }

    .centered-link {
      display: flex;
      align-items: center;
    }

    .link-text {
      vertical-align: middle;
    }

  }

  .sign-up-container {
    left: 0;
    width: 65%;
    opacity: 0;
    z-index: 1;
  }

  .container.right-panel-active {
    .sign-in-container {
      &:nth-child(2) {
        display: none;
      }
    }
  }

  .container.right-panel-active .sign-up-container {
    left: 35%;
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
    background-color: #fff;
  }

  @keyframes show {
    0%,
    49.99% {
      opacity: 0;
      z-index: 1;
    }

    50%,
    100% {
      opacity: 1;
      z-index: 5;
    }
  }

  .overlay-container {
    position: absolute;
    top: 0;
    left: 65%;
    width: 35%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
  }

  .container.right-panel-active .overlay-container {
    left: 0;
  }

  .overlay {
    color: #ffffff;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
  }

  .container.right-panel-active .overlay {
    transform: translateX(50%);
  }

  .overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    padding-top: 80px;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
    background-size: 120px 24px;

    h1 {
      font-weight: normal;
    }
  }

  .overlay-left {
    transform: translateX(-20%);
  }

  .container.right-panel-active .overlay-left {
    transform: translateX(0);
  }

  p {
    margin: 0;
    padding: 0;
  }

  .stt {
    font-size: 14px;
    color: #7E8694;
    margin-top: 10px;
    font-weight: 500;

    span {
      color: #0AD8A7;
      cursor: pointer;
      font-size: 14px;
    }
  }

  .overlay-right {
    right: 0;
    transform: translateX(0);
  }

  .container.right-panel-active .overlay-right {
    transform: translateX(20%);
  }

  .social-container {
    margin: 20px 0;
  }

  :deep .el-input__wrapper {
    background: #EBF2F5 !important;
    border-radius: 8px;
    height: 40px;
    line-height: 40px;
  }

  :deep .el-input-group__append {
    height: 40px;
    line-height: 40px;
    border-radius: 8px;
    background: white !important;
    margin-left: 10px;
    border: 1px solid #CDD2DA;
    box-shadow: 0 0 0 0;
    cursor: pointer;

    &:hover {
      border: 1px solid #059E84;
      background: #E6F5F3;
      color: #059E84;
    }
  }
}

</style>
<style lang="scss">
.login-dialog-box {
  border-radius: 16px !important;
  overflow: hidden;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
    min-height: 480px;
  }
}
</style>
