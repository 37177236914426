import Cookies from 'js-cookie'

const getters = {
  sidebar: (state) => state.sidebar,
  loginDialog: (state) => state.loginDialog
}

const state = {
  sidebar: {
    opened: true,
    withoutAnimation: false
  }
}

const mutations = {
  toggleSideBar: (state) => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    Cookies.set('sidebarStatus', state.sidebar.opened ? 1 : 0)
  },
  closeSideBar: (state, withoutAnimation) => {
    Cookies.set('sidebarStatus', 0)
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  toggleSideBarHide: (state, status) => {
    state.sidebar.hide = status
  }
}

const actions = {
  toggleSideBar({ commit }) {
    commit('toggleSideBar')
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('closeSideBar', withoutAnimation)
  }
}

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
}
