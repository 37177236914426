<template>
  <div>
    <qrcode-vue :value="link" :size="220" level="H" foreground="black"/>
  </div>
</template>
<script setup>
import { ElDescriptions, ElDescriptionsItem, ElMessage } from 'element-plus'
import { reqAddOrder, requestOrderStatus } from '@/api/order'
import { ref, onBeforeUnmount, defineEmits } from 'vue'
import QrcodeVue from 'qrcode.vue'
import { reqListBossHotels } from '@/api/hotel'

const link = ref(undefined)
const emit = defineEmits('onPayEnd')

const props = defineProps({
  hotelId: {
    type: Number,
    default: 0
  },
  fee: {
    type: Number,
    default: 0
  },
  combo: {
    type: String,
    default: ''
  }
})

const timer = ref(null)
const clearTimer = () => {
  clearInterval(timer.value)
  timer.value = null
}
const initQrCode = async () => {
  console.log('开始')

  clearTimer()

  const params = { fee: props.fee, combo: props.combo, hotelId: props.hotelId }
  try {
    const { code, data } = await reqAddOrder(params)
    if (code === 200) {
      link.value = data.link
      timer.value = setInterval(async() => {
        const res = await requestOrderStatus(data.orderNo)

        if (res.data === 2) {
          clearTimer()
          emit('onPayEnd')
          ElMessage.success('支付成功')
        }
      }, 1000 * 2)
    }
  } catch (error) {
    console.error('打印错误信息:', error)
  }
}
/**
 * 初始化二维码
 */
initQrCode()

onBeforeUnmount(() => {
  console.log('销毁')
  clearTimer()
})
defineExpose({ clearTimer })
</script>
