import PCard from './Card'
import Platforms from './Platforms'
import Fullcalender from './Fullcalender'
export const componentPlugin = {
  install(app) {
    app.component('PCard', PCard)
    app.component('Platforms', Platforms)
    app.component('Fullcalender', Fullcalender)
  }
}
