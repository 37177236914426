import { createApp } from 'vue'
import GuideDialog from './index.vue'
import router from '@/router'

const createDialog = (to, callback) => {
  const mountNode = document.createElement('div')
  const Instance = createApp(GuideDialog, {
    confirm: () => {
      Instance.unmount()
      document.body.removeChild(mountNode)
    },
    callback: (param) => callback(param)
  })
  document.body.appendChild(mountNode)
  Instance.mount(mountNode)
}
export default createDialog
