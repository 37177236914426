import request from '@/utils/request'

// 分页请求用户列表
export const reqPageListEmployees = (data) => {
  return request({
    url: '/user/pageListEmployees',
    method: 'post',
    data
  })
}

/**
 * 请求新增员工
 * @param data
 * @returns {AxiosPromise}
 */
export const reqAddEmployee = (data) => {
  return request({
    url: '/user/addEmployee',
    method: 'post',
    data
  })
}

/**
 * 删除用户
 * @param id
 * @returns {AxiosPromise}
 */
export const reqDelEmployee = (id) => {
  return request({
    url: `/user/delEmployee/${id}`,
    method: 'delete'
  })
}

// 请求修改用户
export const reqUpdateEmployee = (data) => {
  return request({
    url: '/user/updateEmployee',
    method: 'put',
    data
  })
}
// 请求获取个人中心
export const requestMeDetails = () => {
  return request({
    url: '/user/getMeDetails',
    method: 'get'
  })
}

// 编辑个人信息
export const requestUpdateMeDetails = (data) => {
  return request({
    url: '/user/updateMeDetails',
    method: 'post',
    data
  })
}
// 修改密码
export const requestUpdatePasswd = (data) => {
  return request({
    url: '/user/updatePasswd',
    method: 'post',
    data
  })
}

// 修改手机
export const requestUpdateMobile = (data) => {
  return request({
    url: '/user/updateMobile',
    method: 'post',
    data
  })
}

// 修改邮箱
export const requestUpdateEmail = (data) => {
  return request({
    url: '/user/updateEmail',
    method: 'post',
    data
  })
}

// 获取账号设置信息
export const requestAccountSetting = () => {
  return request({
    url: '/user/getAccountSetting',
    method: 'get'
  })
}

// 获取前台路由
export const requestGetClientRouter = () => {
  return request({
    url: '/user/getClientRouter',
    method: 'get'
  })
}

/**
 * 获取不同系统模块下的菜单
 */
export const requestListMenus = (module) => {
  return request({
    url: `/user/listMenus/${module}`,
    method: 'get'
  })
}

/**
 * 获取员工下啦列表
 */
export const reqListEmployees = () => {
  return request({
    url: '/user/listEmployees',
    method: 'get'
  })
}
