<template>
  <section class="layout-container">
    <!-- 头部 -->
    <nav-bar/>
    <!-- 中间 -->
    <el-row :gutter="24" class="app-main">
      <el-col>
        <router-view/>
      </el-col>
    </el-row>
    <call-me/>
    <!-- 底部 -->
    <footer-bottom/>
    <!-- 右下角回到顶部 -->
    <el-backtop :right="10" :bottom="20"/>
  </section>
</template>
<script setup>
import { onMounted, onBeforeUnmount, ref } from 'vue'
import NavBar from './components/NavBar'
import FooterBottom from './components/Bottom'
import CallMe from './components/CallMe'
import GuideDialog from './components/GuideDialog/index'
import { useStore } from 'vuex'
import { getToken } from '@/utils/auth'
import { requestUserDetail } from '@/api/auth'
import router from '@/router'

const store = useStore()

const initUserDetail = async () => {
  if (!getToken()) return
  try {
    const { code, data } = await requestUserDetail()
    if (code === 200) {
      const attach = data.attach
      if (attach.used === 'N') {
        GuideDialog('/', guideCbk)
      }
      await store.dispatch('auth/setExpire', attach.expire)
      await store.dispatch('auth/setHotelId', String(attach.hotelId))
      const hotels = attach.hotels
      if (hotels !== null) {
        await store.dispatch('auth/setHotels', hotels)
      }
      const bossHotels = attach.bossHotels
      if (bossHotels !== null) {
        await store.dispatch('auth/setBossHotels', bossHotels)
      }
    }
  } catch (error) {
    console.error(error)
  }
}

const guideCbk = () => router.push('me/hotel/my-hotel').catch(() => console.log('路由跳转失败!'))

/**
 * 初始化用户详情
 */
initUserDetail()

</script>
<style lang="scss" scoped>

.layout-container {
  background-color: #f6f6f6;

  .app-main {
    padding-top: 64px;

    & > .el-col {
      padding-right: 0 !important;
    }
  }
}
</style>
