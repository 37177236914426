<script setup>
import { ref } from 'vue'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
// import timeGridPlugin from '@fullcalendar/timegrid'
// import interactionPlugin from '@fullcalendar/interaction'
import { getCalederData } from '@/api/compare-price.js'

import zhLocale from '@fullcalendar/core/locales/zh-cn'

const getEvents = (info, successCallback) => {
  return successCallback(getCalederData())
}
const calendarOptions = ref({
  locale: zhLocale,
  plugins: [
    dayGridPlugin
    // timeGridPlugin,
    // interactionPlugin // needed for dateClick
  ],
  headerToolbar: {
    left: 'prev,next today',
    center: 'title',
    right: ''
  },
  initialView: 'dayGridMonth',
  initialEvents: [], // alternatively, use the `events` setting to fetch from a feed
  editable: true,
  selectable: true,
  selectMirror: true,
  dayMaxEvents: true,
  weekends: false,
  events: getEvents
  // select: this.handleDateSelect,
  // eventClick: this.handleEventClick,
  // eventsSet: this.handleEvents
  /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
})
// const cc = ref('123')
// const renderCalader = (val) => {
//   calendarOptions.value.initialEvents = val
//   console.log('go')
// }

// setTimeout(() => {
//   initialEvents.value = [{
//     id: 1,
//     title: 'title1',
//     start: '2024-02-26',
//     end: '2024-02-28'
//   },
//   {
//     id: 2,
//     title: 'title1',
//     start: '2024-02-26',
//     end: '2024-02-29'
//   }]
// }, 2000)

// defineExpose({ calendarOptions })

</script>

<template>
  <div class='demo-app'>

    <div class='demo-app-main'>
      <FullCalendar
        class='demo-app-calendar'
        :options='calendarOptions'
      >
      </FullCalendar>
    </div>
  </div>
</template>

<style lang='css'>

.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
  padding: 2em;
}

.demo-app-main {
  flex-grow: 1;
  padding: 0 20px 0 0;

}
.fc-toolbar-chunk{
  margin-right: 20px;
}

.fc { /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}
.fc .fc-toolbar {
  justify-content: left;
}
.fc .fc-toolbar-title{
  color: #646C7E;
}
.fc .fc-button-primary{
  background-color: #0ED9A8;
  border-color: #0ED9A8
}
.fc .fc-button-primary:hover{
  background-color: #0ED9A8;
  border-color: #0ED9A8;
  opacity: .9;
}
.fc .fc-button:focus,.fc .fc-button-primary:not(:disabled).fc-button-active:focus, .fc .fc-button-primary:not(:disabled):active:focus{
  box-shadow:none
}

.fc .fc-button-primary:active,.fc .fc-button-primary:disabled,.fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active{
  background-color: #0ED9A8;
  border-color: #0ED9A8;
  opacity: .8;
}
.fc .fc-button-primary:disabled{
  opacity: .3;
}
.fc .fc-toolbar-title{
  font-weight: initial;
}
.fc .fc-col-header-cell-cushion{
  color: #646C7E;
}
.fc .fc-daygrid-day-number{
  color: #666;
}
.fc-h-event .fc-event-main{
  background-color: #F0E9FE;
  color: #8952F7;

}
.fc-h-event{
  border-color: #F0E9FE;
}
</style>
