<template>
  <div class="side-bar-wrap">
    <el-scrollbar>
      <el-menu
        :default-active="defaultActive"
        :collapse="isCollapse"
        :unique-opened="true"
        :collapse-transition="false"
        mode="vertical"
        active-text-color="#059E84"
        text-color="#505762"
        background-color="#F0F2F5"
      >
        <menu-item
          v-for="(route, index) in menus"
          :key="route.path + index"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
    <div class="collapse-buttons">
      <RHamburger
        :is-active="sidebar.opened"
        class="hamburger-container collapse-btn"
        @toggle-click="toggleClick"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import MenuItem from './MenuItem'
import RHamburger from '@/components/RHamburger'
import { requestListMenus } from '@/api/user'

const route = useRoute()
const store = useStore()
const sidebar = store.getters['app/sidebar']
const isCollapse = computed(() => !sidebar.opened)
const toggleClick = () => store.dispatch('app/toggleSideBar')
const menus = ref([])

const defaultActive = computed(() => route.path)

/**
 * 改变控制台菜单
 */
const change = (value) => listMenus(value)

const listMenus = async (module) => {
  try {
    const { code, data } = await requestListMenus(module)
    if (code === 200) {
      menus.value = data
    }
  } catch (error) {
    console.error(error)
  }
}

const initMenus = () => listMenus(route.path.includes('compare-price') ? 'compare' : 'comment')

/**
 * 初始化菜单列表
 */
initMenus()

defineExpose({
  change
})
</script>
<style lang="scss" scoped>
:deep(.el-menu) {
  border: 0;
  background-color: #FBFBFB;
}

:deep(.el-menu-item) {
  // border-radius: 8px;
  height: 48px;
  line-height: 48px;
}

:deep(.el-menu-item:hover) {
  background-color: rgba(5, 158, 132, 0.08);
}

:deep(.el-menu-item.is-active) {
  background-color: rgba(5, 158, 132, 0.08);
}

:deep(.el-scrollbar__view) {
  padding-top: 6px;
}

.collapse-buttons {
  border-top: 1px solid #E6EAF0;
  display: flex;
  height: 56px;
  margin-left: 8px;
  align-items: center;

  .collapse-btn {
    cursor: pointer;
  }
}

.side-bar-wrap {
  display: flex;
  flex-direction: column;
}
</style>
<style lang="scss">
.side-bar-wrap .el-menu div > a {
  padding: 0;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
}

.hideSidebar .side-bar-wrap .el-menu div > a {
  padding: 4px;
}
</style>
