<template>
  <div class="account-sidebar-container">
    <div class="avatar avatar-uploader">
      <div class="avatar-uploader">
        <el-image :src="avatar" class="avatar"/>
      </div>
    </div>
    <el-menu
      default-active="1"
      :unique-opened="true"
      :collapse-transition="false"
      mode="vertical"
      active-text-color="#059E84"
      text-color="#505762"
    >
      <menu-item
        v-for="(route, index) in menus"
        :key="route.path + index"
        :item="route"
        :base-path="route.path"
      />
    </el-menu>
  </div>
</template>
<script setup>
import { ref, computed, getCurrentInstance } from 'vue'
import { tokenConfig } from '@/config'
import { getToken } from '@/utils/auth'
import { useStore } from 'vuex'
import logo from '@/assets/logo/user-logo.png'
import MenuItem from './MenuItem'
import { requestListMenus } from '@/api/user'

const store = useStore()
const getAvatar = () => store.getters['auth/avatar']
const avatar = computed(() => getAvatar() ? getAvatar() : logo)
// 图片上传地址
const url = ref(process.env.VUE_APP_BASE_API + '/user/updateMeLogo')
const headers = ref({ Authorization: tokenConfig.prefix + getToken() })
// 菜单列表
const menus = ref([])
const { proxy } = getCurrentInstance()
// 返回图标颜色
// const currentIndex = ref(route.name) // 选中的菜单index
// 跳转详情
// const toDetail = (path, index) => {
//   currentIndex.value = index
//   router.push(path)
// }
// 上传成功
const success = (response) => {
  if (response.code === 200) {
    proxy.$modal.msgSuccess('修改用户图像成功!')
    store.dispatch('auth/setAvatar', response.data)
  }
}

/**
 * 初始化菜单列表
 */
const initMenus = async () => {
  try {
    const { code, data } = await requestListMenus('person')
    if (code === 200) {
      menus.value = data
    }
  } catch (error) {
    console.error(error)
  }
}

initMenus()
</script>
<style lang="scss" scoped>
.account-sidebar-container {
  width: 300px;
  margin-top: 60px;
  border-radius: 5px;
  box-shadow: 0 5px 21px 0 rgb(78 78 78 / 25%);
  min-height: 300px;
  overflow: hidden;

  .el-menu {
    border-right: 0;
  }

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 220px;

    .avatar-uploader {
      width: 75px;
      height: 75px;
      border-radius: 50%;
      // background: #00a7eb;
      position: relative;

      .avatar {
        width: 100%;
        height: 100%;
        // width: 65px;
        // height: 65px;
      }
    }
  }
}
</style>
