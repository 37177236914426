<template>
  <div class="avatar-container">
    <el-dropdown
      @command="handleCommand"
      class="right-menu-item hover-effect"
      trigger="hover"
    >
      <div class="avatar-wrapper">
        <img :src="avatar" class="user-avatar"/>
        <!-- <el-icon>
          <caret-bottom/>
        </el-icon> -->
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <router-link to="/me" v-if="!subAcct">
            <el-dropdown-item>个人中心</el-dropdown-item>
          </router-link>
          <el-dropdown-item divided command="logout">
            <span>退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'
import logo from '@/assets/logo/user-logo.png'
import { ElMessageBox } from 'element-plus'

const store = useStore()
const getAvatar = () => store.getters['auth/avatar']
const avatar = computed(() => getAvatar() ? getAvatar() : logo)
const getSubAcct = () => store.getters['auth/subAcct']
const subAcct = computed(() => getSubAcct())

const handleCommand = (command) => {
  const commands = {
    logout: logout
  }
  if (commands[command]) {
    commands[command]()
  }
}
const logout = async () => {
  const { confirm } = ElMessageBox
  try {
    await confirm('确定注销并退出系统吗？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
    await store.dispatch('auth/logout')
    location.href = '/'
  } catch (error) {
    console.log('退出系统错误日志:' + error)
  } finally {
    // TODO 处理用户相关的信息
  }
}
</script>
<style lang="scss">
.avatar-container {
  // margin-right: 40px;

  .avatar-wrapper {
    margin-top: 5px;
    position: relative;

    .user-avatar {
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }

    i {
      cursor: pointer;
      position: absolute;
      right: -20px;
      top: 25px;
      font-size: 12px;
    }
  }
}
</style>
