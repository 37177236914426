<template>
  <div class="logo"  @click="toIndex">
    <img :src="logo">
  </div>
</template>

<script setup>
import logo from '@/layout/components/assets/imgs/logo.png'
import { useRouter } from 'vue-router'

const router = useRouter()

/**
 * 跳转首页
 */
const toIndex = () => router.push('/')
</script>

<style lang="scss" scoped>
.logo{
  display: flex;
  align-items: center;
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
  color: #505762;
  cursor: pointer;
  img{
    // width: 24px;
    height: 24px;
    margin-right: 8px;
    border-radius: 4px;
  }
}
</style>
