<template>
  <div class="platforms">

    <div class="comment-details">
      <el-tabs type="border-card" v-model="modelValue" @tab-change="(val)=>{$emits('update:modelValue',val)}">
        <el-tab-pane :key="item.value" :name="item.value"  v-for="item in platformList" >
          <template #label>
            <span class="tabs-label">
              <img  :src="require(`../../assets/images/platform/${item.icon}.png`)">
              <span>{{item.label}}</span>
              <span class="line animation03"></span>
            </span>
          </template>
        </el-tab-pane>

      </el-tabs>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps, defineEmits, toRefs } from 'vue'

const props = defineProps(['modelValue'])
const { modelValue } = toRefs(props)
const $emits = defineEmits(['update:modelValue'])

const platformList = ref([
  {
    label: '美团',
    icon: 'meiTuan',
    value: 'meituan'
  },
  {
    label: '飞猪',
    icon: 'feiZhu',
    value: 'feizhu'
  },
  {
    label: '携程',
    icon: 'xieCheng',
    value: 'xiecheng'
  },
  {
    label: '去哪儿',
    icon: 'quNaEr',
    value: 'quna'
  },
  {
    label: '同程',
    icon: 'tongCheng',
    value: 'tongcheng'
  }
])

</script>
<style lang="scss" scoped>
// .comment-container{
//   // padding: 10px;
//   .comment-details{
//     margin-top: 16px;
//   }
// }
// :deep(.is-always-shadow){
//   box-shadow: none
// }
// :deep(.el-card__body){
//   padding: 16px;
// }

// :deep(.el-tabs--border-card){
//   border-radius: 4px;
//   overflow: hidden;
// }

// :deep(.el-tabs--border-card>.el-tabs__content){
//   padding-left: 20px;
//   padding-right: 20px;
// }

// tabs ui
.tabs-label{
  display: flex;
  align-items: center;
  color: #7E8694;
  font-weight: bold;
  font-size: 16px;
  margin-top: 20px;
  position: relative;
  img{
    height: 25px;
    margin-right: 4px;
  }
  .line{
    width: 0;
    height: 2px;
    background: #0ED9A8;
    position: absolute;
    bottom: 3px;
    left: 50%;

  }
}
:deep(.el-tabs__item.is-active){
  &>span>span{
    color: #0ED9A8;
  }
}
:deep(.el-tabs__item.is-active .line){

  width: 100%;
  left: 0;
}
:deep(.el-tabs--border-card){
  border: 0;
  border-radius: 8px;
}
:deep(.el-tabs--border-card>.el-tabs__header){
  background-color: #fff;
  border-bottom: 0;
}
:deep(.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active){
  border: 0;
}
:deep(.el-tabs--border-card>.el-tabs__header .el-tabs__item){
  border: 0;
}
:deep(.el-tabs__nav){
  // position: relative;
  // &::before{
  //   content: '';
  //   position: absolute;
  //   left: 0;
  //   top: 31px;
  //   width: 3px;
  //   height: 16px;
  //   background: #0ED9A8;
  //   z-index: 1;
  // }
}
</style>
