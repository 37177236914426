<template>
  <div class="navbar">
    <div class="l">
      <LogoNew/>
      <div class="hotels">
        <!-- <span>酒店:</span> -->
        <img class="jiu-dian"  :src="require('../../../assets/images/jiudian.png')">
        <span>&nbsp;</span>
        <el-select
          v-model="currentHotelId"
          @change="handleChangHotelId"
        >
          <el-option
            v-for="item in getHotels()"
            :key="item.hotelId"
            :label="item.hotelName"
            :value="item.hotelId"
          />
        </el-select>
      </div>
    </div>
    <div class="right-menu">
      <Avatar/>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from 'vue'
import { useStore } from 'vuex'
import LogoNew from '@/console/components/SideBar/LogoNew.vue'
import Avatar from '@/components/Avatar'
import { requestListMyHotels } from '@/api/hotel'
import router from '@/router'
import { getHotels } from '@/utils/hotel'

const store = useStore()
const emit = defineEmits(['updateRouterView'])

const currentHotelId = ref(store.getters['auth/hotelId'])

const handleChangHotelId = (event) => {
  store.dispatch('auth/setHotelId', event)
  emit('updateRouterView')
}

// const initMyHotels = async () => {
//   try {
//     const { code, data } = await requestListMyHotels()
//     if (code === 200) {
//       hotels.value = data.map(item => ({ ...item, hotelId: String(item.hotelId) }))
//     }
//   } catch (error) {
//
//   }
// }
/**
 * 初始化酒店列表
 */
// initMyHotels()
</script>

<style lang="scss" scoped>
:deep(.el-select .el-input__wrapper) {
  // background-color: #F0F2F5;
  box-shadow: none
}
:deep(.el-select .el-input.is-focus .el-input__wrapper){
  box-shadow: none;
}

:deep(.el-select:hover:not(.el-select--disabled) .el-input__wrapper) {
  box-shadow: none;
}

.hotels {
  display: flex;
  align-items: center;
  height: 30px;
  margin: 0 16px;
  padding-left: 8px;
  font-size: 14px;
  border-radius: 4px;
  color: #1C2026;
}

.navbar {
  position: fixed !important;
  top: 0;
  left: 0;
  height: 50px;
  width: 100%;
  overflow: hidden;
  position: relative;
  background: #fff;
  // box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  border-bottom: 1px solid #E6EAF0;
  z-index: 2070;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;

  .l {
    display: flex;
    align-items: center;
    width: 50%;
  }

  .select-hotel {
    width: 200px;
  }

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;
    display: flex;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }
  }
}
.jiu-dian{
  height: 21px;
}
</style>
