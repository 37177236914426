<template>
   <el-card class="p-card" >
      <template #header v-if="title">
        <div class="card-header">
          <span>{{title}}</span>
          <slot name="right"></slot>
        </div>
      </template>
      <slot></slot>
    </el-card>
</template>

<script setup>
import { defineProps, toRefs } from 'vue'

const props = defineProps({
  title: {
    type: String
  }

})
const { title } = toRefs(props)

</script>

<style lang="scss" scoped>
  .p-card{
    margin-top: 16px;
    box-shadow: none !important;
    border: 0;
  }
  :deep(.el-card__header){
    border-bottom: 0;
  }
  :deep(.card-header){
    // color: rgb(28, 32, 38);
    color: #7E8694;
    font-weight: bold;
    font-size: 16px;
    position: relative;
    display: flex;
    justify-content: space-between;
    &::before{
      content: '';
      width: 3px;
      height: 16px;
      background: #0ED9A8;
      position: absolute;
      left: -20px;
      top: 3px;
    }

  }

</style>
