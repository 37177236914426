import { createApp } from 'vue'
import LoginDialog from './index.vue'
import router from '@/router'

const createDialog = (isLogin = false, to = '/', callback) => {
  const mountNode = document.createElement('div')
  const Instance = createApp(LoginDialog, {
    isLogin: isLogin,
    confirm: () => {
      if (to) {
        router.push(to).catch(() => console.log('路由跳转失败!'))
        location.reload()
      }
      Instance.unmount()
      document.body.removeChild(mountNode)
    },
    callback: (param) => callback(param)
  })
  document.body.appendChild(mountNode)
  Instance.mount(mountNode)
}

export default createDialog
