const getters = {
  hotelId: (state) => state.token,
  hotels: (state) => state.hotels
}

const state = {
  hotelId: null,
  hotels: []
}

const mutations = {
  setHotels: (state, hotels) => {
    state.hotels = hotels
  },
  setHotelId: (state, hotelId) => {
    state.hotelId = hotelId
  }
}

const actions = {
  async setHotelInfo({ commit }, attach) {
    commit('setHotels', attach.hotels)
    commit('setHotelId', attach.hotelId)
  },
  async setHotelId({ commit }, hotelId) {
    commit('setHotelId', hotelId)
  }
}

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
}
