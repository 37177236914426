import request from '@/utils/request'

// 获取比价规划
export const requestListPricePlains = (data) => {
  return request({
    url: '/comparePrice/listPricePlains',
    method: 'post',
    data
  })
}

// 获取酒店告警
export const requestListPriceAlarms = (data) => {
  return request({
    url: '/comparePrice/listAlarmPrices',
    method: 'post',
    data
  })
}

// 获取最新时间
export const requestLastTime = (data) => {
  return request({
    url: '/comparePrice/getLastTime',
    method: 'post',
    data
  })
}

// 获取最新比价告警列表
export const requestLastPriceAlarms = (data) => {
  return request({
    url: '/comparePrice/listLastAlarmPrices',
    method: 'post',
    data
  })
}

// 获取比价渠道
export const requestListPriceChannels = () => {
  return request({
    url: '/comparePrice/channel',
    method: 'post'
  })
}

// 获取价格趋势图
export const requestListPriceTrend = () => {
  return request({
    url: '/comparePrice/channel/trend',
    method: 'post'
  })
}

// 获取比较详情
export const requestListPriceDetail = () => {
  return request({
    url: '/comparePrice/channel/trend',
    method: 'post'
  })
}

// 比价首页-获取渠道价格最新时间
export const getChannelPriceMaxTime = (hotelId) => {
  return request({
    url: `/compare/getChannelPriceMaxTime/${hotelId}`,
    method: 'get'
  })
}

// 比价首页-获取渠道价格列表
export const listChannelPrices = (hotelId) => {
  return request({
    url: `/compare/listChannelPrices/${hotelId}`,
    method: 'get'
  })
}

// 比价首页-获取全网引流趋势
export const listChannelTrends = (data) => {
  return request({
    url: '/compare/listChannelTrends',
    method: 'post',
    data
  })
}

// 比价首页-获取全网引流趋势
export const listCompareDetails = (data) => {
  return request({
    url: '/compare/listCompareDetails',
    method: 'post',
    data
  })
}

// 价格规划-获取价格规划
export const listPlainTrends = (data) => {
  return request({
    url: '/compare/listPlainTrends',
    method: 'post',
    data
  })
}

// 比价预警-获取最近告警房型列表
export const listLastAlarmRooms = (data) => {
  return request({
    url: '/compare/listLastAlarmRooms',
    method: 'post',
    data
  })
}

// 比价预警-获取比价预警趋势图
export const listAlarmTrends = (data) => {
  return request({
    url: '/compare/listAlarmTrends',
    method: 'post',
    data
  })
}

// 比价预警-获取预警阀值
export const getAlarmThreshold = (hotelId) => {
  return request({
    url: `/compare/getAlarmThreshold/${hotelId}`,
    method: 'post'

  })
}

// 比价预警-修改预警阀值
export const updateAlarmThreshold = (data) => {
  return request({
    url: '/compare/updateAlarmThreshold',
    method: 'post',
    data
  })
}

// 比价预警-日历
export const getCalederData = (data) => {
  return [
    {
      id: 1,
      title: '1月1日至3日放假调休，共3天。1月4日（星期日）上班。',
      start: '2024-02-26',
      end: '2024-02-28'
    },
    {
      id: 2,
      title: '2月18日至24日放假调休，共7天。2月15日（星期日）、2月28日（星期六）上班。',
      start: '2024-02-26',
      end: '2024-02-28'
    },
    {
      id: 3,
      title: '4月5日放假，4月6日（星期一）补休。',
      start: '2024-02-26',
      end: '2024-02-28'
    }

  ]
}
