import { createApp } from 'vue'
import LoginDialog from './index.vue'

const createDialog = (hotelId, callback) => {
  const mountNode = document.createElement('div')
  const Instance = createApp(LoginDialog, {
    hotelId: hotelId,
    confirm: () => {
      Instance.unmount()
      document.body.removeChild(mountNode)
    },
    callback: (param) => callback(param)
  })
  document.body.appendChild(mountNode)
  Instance.mount(mountNode)
}

export default createDialog
