<template>
  <div class="account-header-container">
    <!-- LOGO -->
    <Logo/>
    <!-- 右侧 -->
    <div class="right">
      <div class="navs">
        <div class="navlist">
          <div class="navitem">
            <p @click="handleMenuSelect('comment')">点评运营</p>
          </div>
          <div class="navitem">
            <p @click="handleMenuSelect('compare')">价格运营</p>
          </div>
          <div class="navitem">
            <p @click="toChat">AI图片</p>
          </div>
        </div>
      </div>
      <div class="user-dropdown">
        <div class="user-avatar-box">
          <Avatar/>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Logo from '@/components/Logo'
import Avatar from '@/components/Avatar'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import OrderDialog from '@/components/OrderDialog'
import LoginDialog from '@/components/LoginDialog'
import { getBossHotels } from '@/utils/hotel'
import GuideDialog from '@/layout/components/GuideDialog'
import { requestUserDetail } from '@/api/auth'
const store = useStore()
const router = useRouter()

/**
 * 跳转控制台
 */
const toMember = () => router.push('/member')

const handleMenuSelect = async (key) => {
  let route
  const expire = store.getters['auth/expire']
  if (expire === 'N') {
    if (key === 'comment') {
      route = '/console/home'
    }
    if (key === 'compare') {
      route = '/console/compare-price/home'
    }
    router.push(route).then((error) => console.error(error))
  } else {
    const bossHotels = getBossHotels()
    if (bossHotels.length === 0) {
      GuideDialog('/', guideCbk)
    } else {
      const hotelId = bossHotels[0].hotelId
      OrderDialog(hotelId, orderCbk())
    }
  }
}

const guideCbk = () => router.push('me/hotel/my-hotel').catch(() => console.log('路由跳转失败!'))

const orderCbk = async () => {
  try {
    const { code, data } = await requestUserDetail()
    if (code === 200) {
      const attach = data.attach
      await store.dispatch('auth/setExpire', attach.expire)
      await store.dispatch('auth/setHotelId', String(attach.hotelId))
      const hotels = attach.hotels
      if (hotels !== null) {
        await store.dispatch('auth/setHotels', hotels)
      }
      const bossHotels = attach.bossHotels
      if (bossHotels !== null) {
        await store.dispatch('auth/setBossHotels', bossHotels)
      }
    }
  } catch (error) {
    console.error(error)
  }
}
const toChat = () => router.push('/chat')

</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.module.scss';

.account-header-container {
  width: 100%;
  background: #ffffff;
  display: flex;
  height: 65px;
  justify-content: space-between;
  padding: 0 30px;
  position: fixed;
  top: 0;
  z-index: 100;
  .center {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .right {
    display: flex;
    align-items: center;

    .member {
      display: flex;
      align-items: center;
      margin-right: 15px;

      .el-button {
        height: 30px;
      }
    }

    .user-avatar-box {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 15px;
      cursor: pointer;

      .name-content {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: #ffffff;
        background-color: #ffffff;
      }
    }
  }

}

</style>
<style lang="scss" scoped>
.login-register-box {
  display: flex;

  .el-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 65px;
  }

  .register-btn {
    margin-left: 15px;
  }
}
.navs {
  display: flex;
  align-items: center;

  .navlist {
    display: flex;
    height: 44px;

    .navitem {
      margin-right: 48px;
      position: relative;

      &.has {
        & > p {
          display: flex;
          align-items: center;

          &::after {
            content: '';
            width: 6px;
            height: 6px;
            margin-top: -3px;
            transform: rotate(45deg);
            margin-left: 7px;
            border-bottom: 1px solid #404040;
            border-right: 1px solid #404040;
          }
        }
      }

      & > p {
        line-height: 44px;
        text-align: center;
        margin: 0;
        padding: 0;
        cursor: pointer;

        &:hover {
          color: #0AD8A7;
        }
      }

      &:hover {
        .childs {
          opacity: 1;
          visibility: visible;
          transform: translateY(0px);
        }
      }

      .childs {
        position: absolute;
        width: 200%;
        margin-left: -50%;
        text-align: center;
        line-height: 44px;
        background: white;
        height: 88px;
        visibility: hidden;
        opacity: 0;
        overflow: hidden;
        transition: all .5s;
        border: 1px solid #f1f1f1;
        border-radius: 5px;
        transform: translateY(20px);

        div {
          cursor: pointer;

          &:hover {
            color: #0AD8A7;
          }
        }
      }
    }
  }

  .btn {
    width: 80px;
    height: 32px;
    border: 1px solid #0AD8A7;
    border-radius: 22px;
    color: #0AD8A7;
    text-align: center;
    line-height: 32px;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      background: #0AD8A7;
      color: #ffff;
    }
  }
}
</style>
