import { getToken, removeToken, setToken } from '@/utils/auth'
import { requestLogout, requestUserDetail } from '@/api/auth'
import {
  getHotelId,
  setHotelId,
  setHotels,
  getHotels,
  setBossHotels,
  getBossHotels,
  removeHotels,
  removeBossHotels, removeHotelId
} from '@/utils/hotel'

const getters = {
  token: (state) => state.token,
  isLogin: (state) => state.token !== undefined && state.token !== '',
  hotelId: (state) => state.hotelId,
  hotels: (state) => state.hotels,
  bossHotels: (state) => state.bossHotels,
  member: (state) => state.member,
  subAcct: (state) => state.subAcct,
  avatar: (state) => state.avatar,
  expire: (state) => state.expire
}

const state = {
  token: getToken(),
  hotelId: getHotelId(),
  hotels: getHotels(),
  bossHotels: getBossHotels(),
  used: 'N',
  subAcct: '',
  expire: 'Y'
}

const mutations = {
  setToken: (state, token) => state.token = token,
  setHotelId: (state, hotelId) => state.hotelId = hotelId,
  setUsed: (state, used) => state.used = used,
  setSubAcct: (state, subAcct) => state.subAcct = subAcct,
  setAvatar: (state, avatar) => state.avatar = avatar,
  setExpire: (state, expire) => state.expire = expire,
  setHotels: (state, hotels) => state.hotels = hotels,
  setBossHotels: (state, bossHotels) => state.bossHotels = bossHotels
}

const actions = {
  async setUserDetail({ commit }) {
    try {
      const { code, data } = await requestUserDetail()
      if (code === 200) {
        const attach = data.attach
        commit('setHotelId', attach.hotelId)
        commit('setAvatar', attach.avatar)
        commit('setMember', attach.memberType)
        commit('setSubAcct', attach.subAcct)
      }
    } catch (error) {
      console.error(error)
    }
  },
  async setHotelId({ commit }, hotelId) {
    setHotelId(hotelId)
    commit('setHotelId', hotelId)
  },
  async setHotels({ commit }, hotels) {
    setHotels(hotels)
    commit('setHotels', hotels)
  },
  async setBossHotels({ commit }, hotels) {
    setBossHotels(hotels)
    commit('setBossHotels', hotels)
  },
  async setAvatar({ commit }, avatar) {
    commit('setAvatar', avatar)
  },
  async setExpire({ commit }, expire) {
    commit('setExpire', expire)
  },
  async setToken({ commit }, token) {
    setToken(token)
    commit('setToken', token)
  },
  async logout({ commit }) {
    await requestLogout()
    commit('setToken', '')
    removeToken()
    removeHotels()
    removeBossHotels()
    removeHotelId()
  }
}

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
}
