<template>
  <div class="header">
    <Logo/>
    <div class="navs">
      <div class="navlist">
        <div class="navitem">
          <p @click="toIndex">首页</p>
        </div>
        <div class="navitem">
          <p>
            <a href="https://doc.rzcode.com">在线文档</a>
          </p>
        </div>
        <div class="navitem">
          <p @click="handleMenuSelect('comment')">点评运营</p>
        </div>
        <div class="navitem">
          <p @click="handleMenuSelect('compare')">价格运营</p>
        </div>
        <div class="navitem">
          <p @click="toChat">AI图片</p>
        </div>
        <div class="navitem">
          <p @click="toBlog">行业互联</p>
        </div>
      </div>
      <div class="user-dropdown">
        <div v-if="!token" class="login-register-box">
          <div class="btn an3" @click="openLoginDialog">登录</div>
        </div>
        <div v-else class="user-avatar-box">
          <Avatar/>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Avatar from '@/components/Avatar'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import LoginDialog from '@/components/LoginDialog/index'
import { getToken } from '@/utils/auth'
import OrderDialog from '@/components/OrderDialog'
import { getBossHotels } from '@/utils/hotel'
import GuideDialog from '@/layout/components/GuideDialog'
import { requestUserDetail } from '@/api/auth'
import Logo from '../../../components/Logo'

const router = useRouter()
const store = useStore()

const props = defineProps({
  bgColor: String,
  txtColor: {
    type: String,
    default: '#ffffff'
  }
})

const token = store.getters['auth/token']

const openLoginDialog = () => LoginDialog(true, '/', loginCallback)

const loginCallback = (token) => store.dispatch('auth/setToken', token)

const handleMenuSelect = async (key) => {
  if (token) {
    let route
    const expire = store.getters['auth/expire']
    if (expire === 'N') {
      if (key === 'comment') {
        route = '/console/home'
      }
      if (key === 'compare') {
        route = '/console/compare-price/home'
      }
      router.push(route).then((error) => console.error(error))
    } else {
      const bossHotels = getBossHotels()
      if (bossHotels.length === 0) {
        GuideDialog('/', guideCbk)
      } else {
        const hotelId = bossHotels[0].hotelId
        OrderDialog(hotelId, orderCbk)
      }
    }
  } else {
    LoginDialog(true, '/', loginCallback)
  }
}

const guideCbk = () => router.push('/me/hotel/my-hotel').catch(() => console.log('路由跳转失败!'))

const orderCbk = async () => {
  try {
    const { code, data } = await requestUserDetail()
    if (code === 200) {
      const attach = data.attach
      await store.dispatch('auth/setExpire', attach.expire)
      await store.dispatch('auth/setHotelId', String(attach.hotelId))
      const hotels = attach.hotels
      if (hotels !== null) {
        await store.dispatch('auth/setHotels', hotels)
      }
      const bossHotels = attach.bossHotels
      if (bossHotels !== null) {
        await store.dispatch('auth/setBossHotels', bossHotels)
      }
    }
  } catch (error) {
    console.error(error)
  }
}

const toMember = () => router.push('/member')
const toBlog = () => router.push('/blog')
const toChat = () => router.push('/chat')
const toIndex = () => router.push('/')
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.module.scss";

.an3 {
  transition: 0.3s;
}

.header {
  height: 65px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  align-items: center;
  background: white;
  position: fixed;
  z-index: 100;
  border-bottom: 1px solid #E6EAF0;
  min-width: 900px;

  .logo {
    height: 33px;
    width: auto;
  }

  .navs {
    display: flex;
    align-items: center;

    .navlist {
      display: flex;
      height: 44px;

      .navitem {
        margin-right: 48px;
        position: relative;

        &.has {
          & > p {
            display: flex;
            align-items: center;

            &::after {
              content: '';
              width: 6px;
              height: 6px;
              margin-top: -3px;
              transform: rotate(45deg);
              margin-left: 7px;
              border-bottom: 1px solid #404040;
              border-right: 1px solid #404040;
            }
          }
        }

        & > p {
          line-height: 44px;
          text-align: center;
          margin: 0;
          padding: 0;
          cursor: pointer;

          &:hover {
            color: #0AD8A7;
          }
        }

        &:hover {
          .childs {
            opacity: 1;
            visibility: visible;
            transform: translateY(0px);
          }
        }

        .childs {
          position: absolute;
          width: 200%;
          margin-left: -50%;
          text-align: center;
          line-height: 44px;
          background: white;
          height: 88px;
          visibility: hidden;
          opacity: 0;
          overflow: hidden;
          transition: all .5s;
          border: 1px solid #f1f1f1;
          border-radius: 5px;
          transform: translateY(20px);

          div {
            cursor: pointer;

            &:hover {
              color: #0AD8A7;
            }
          }
        }
      }
    }

    .btn {
      width: 80px;
      height: 32px;
      border: 1px solid #0AD8A7;
      border-radius: 22px;
      color: #0AD8A7;
      text-align: center;
      line-height: 32px;
      font-size: 16px;
      cursor: pointer;

      &:hover {
        background: #0AD8A7;
        color: #ffff;
      }
    }
  }
}

.header-container {
  position: fixed;
  width: 100%;
  z-index: 99;
  background: v-bind('props.bgColor');
  display: flex;
  justify-content: space-between;
  padding: 0 30px;

  .center {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .el-menu {
      border: none;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .el-menu-item {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: v-bind('props.txtColor');
        padding: 0 15px !important;
        letter-spacing: 3px;
        font-size: 18px;
        border-bottom: 4px solid transparent;
        transition: all 0.3s;

        &:hover {
          border-color: #2ddd9d;
          background-color: rgba(#ccc, 0.1);
        }
      }

      .el-sub-menu {
        height: 100%;
        color: v-bind('props.txtColor');
        border-bottom: 4px solid transparent;

        &:hover {
          border-bottom: 4px solid #2ddd9d !important;
          background-color: rgba(#ccc, 0.1);
        }

        :deep(.el-sub-menu__title) {
          letter-spacing: 3px;
          font-size: 18px;
          color: v-bind('props.fontColor');
          border: none;

          &:hover {
            background-color: rgba(#ccc, 0.1);
          }
        }
      }

      .is-active {
        background-color: rgba(255, 255, 255, 0);
        border-bottom: 4px solid #2ddd9d !important;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;

    .member {
      display: flex;
      align-items: center;
      margin-right: 15px;

      .el-button {
        height: 30px;
      }
    }

    .user-avatar-box {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 15px;
      cursor: pointer;

      .name-content {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: v-bind('nameColor');
        background-color: v-bind('iconColor');
      }
    }
  }
}

</style>
<style lang="scss">
.navbar-popper-box {
  overflow: hidden;
  border: none;

  .el-menu {
    padding: 0;
    min-width: 134px;

    .el-menu-item {
      height: 50px;
      font-size: 14px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: 2px;
    }
  }
}
</style>
<style lang="scss" scoped>
.login-register-box {
  display: flex;

  .el-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 65px;
  }

  .register-btn {
    margin-left: 15px;
  }
}
</style>
