import Cookies from 'js-cookie'

const HotelIdKey = 'HOTEL_ID'
const HotelKey = 'HOTELS'
const BossHotelKey = 'BOSS_HOTELS'

export const getHotelId = () => {
  // return 3
  return Cookies.get(HotelIdKey)
}

export const setHotelId = (hotelId) => {
  return Cookies.set(HotelIdKey, hotelId, { expires: 7 })
}

export const removeHotelId = () => {
  return Cookies.remove(HotelIdKey)
}

export const getHotels = () => {
  const hotelsString = Cookies.get(HotelKey)
  if (hotelsString) {
    try {
      // 尝试将字符串解析为数组
      const hotels = JSON.parse(hotelsString)

      // 确保解析后的值是一个数组
      if (Array.isArray(hotels)) {
        return hotels.map(item => ({ ...item, hotelId: String(item.hotelId) }))
      } else {
        console.error('解析出的 hotels 不是数组:', hotels)
        // 如果不是数组，可以选择抛出错误、返回空数组或进行其他处理
        return []
      }
    } catch (error) {
      console.error('解析 hotels 字符串时出错:', error)
      // 解析失败时返回空数组或进行其他错误处理
      return []
    }
  }

  // 如果没有找到 cookie 或 cookie 的值为空，返回空数组
  return []
}

export const setHotels = (hotels) => {
  return Cookies.set(HotelKey, JSON.stringify(hotels), { expires: 7 })
}

export const removeHotels = () => {
  return Cookies.remove(HotelKey)
}

export const getBossHotels = () => {
  const hotelsString = Cookies.get(BossHotelKey)
  if (hotelsString) {
    try {
      // 尝试将字符串解析为数组
      const hotels = JSON.parse(hotelsString)

      // 确保解析后的值是一个数组
      if (Array.isArray(hotels)) {
        return hotels.map(item => ({ ...item, hotelId: String(item.hotelId) }))
      } else {
        console.error('解析出的 hotels 不是数组:', hotels)
        // 如果不是数组，可以选择抛出错误、返回空数组或进行其他处理
        return []
      }
    } catch (error) {
      console.error('解析 hotels 字符串时出错:', error)
      // 解析失败时返回空数组或进行其他错误处理
      return []
    }
  }

  // 如果没有找到 cookie 或 cookie 的值为空，返回空数组
  return []
}

export const setBossHotels = (bossHotels) => {
  return Cookies.set(BossHotelKey, JSON.stringify(bossHotels), { expires: 7 })
}

export const removeBossHotels = () => {
  return Cookies.remove(BossHotelKey)
}
