import NProgress from 'nprogress'
import router from '@/router'
import store from '@/store'
import { allowlist } from '@/config/setting.config'
import { isHttp } from '@/utils/validate'

NProgress.configure({ showSpinner: false })

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  const token = store.getters['auth/token']
  if (token) {
    try {
      if (!store.getters['permission/generated']) {
        // 生成动态路由
        await store.dispatch('permission/generatorRouters')
        // 组装路由
        const routes = store.getters['permission/addRoutes']
        routes.forEach((route) => {
          // 动态添加可访问路由表
          if (!isHttp(route.path)) {
            router.addRoute(route)
          }
        })
        // 重新导航到目标路由，replace: true 避免在浏览器历史记录中创建新条目
        next({ ...to, replace: true })
      } else {
        next()
      }
    } catch (error) {
      NProgress.done()
      await store.dispatch('auth/logout')
      next('/')
      console.error(error)
    }
  } else {
    if (isPathAllowed(to.path)) {
      next()
    } else {
      next('/')
    }
  }
})

router.afterEach(() => NProgress.done())

const isPathAllowed = (path) => {
  for (const route of allowlist) {
    if (route === path) {
      return true
    }
    if (isDynamicRoute(route) && matchesDynamicRoute(route, path)) {
      return true
    }
  }
  return false
}

const isDynamicRoute = (route) => {
  return route.includes('/:')
}

const matchesDynamicRoute = (route, path) => {
  const routeRegex = new RegExp(
    `^${route.replace(/:\w+/g, '\\w+').replace(/\//g, '\\/')}$`
  )
  return routeRegex.test(path)
}
